import { Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { Query } from '@datorama/akita';
import { AdminUser, ProfileSetting } from '@memberspot/shared/model/admin-user';
import { Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';

import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  profile$ = this.select('profile');
  roles$ = this.select('roles');
  uid$ = this.select('uid');

  get user$() {
    return user(this._afAuth);
  }

  constructor(
    protected override store: AuthStore,
    private _afAuth: Auth,
  ) {
    super(store);
  }

  getUID() {
    return this.getValue().uid;
  }

  selectProfile() {
    return this.profile$;
  }

  selectSettings(): Observable<AdminUser['settings']> {
    return this.profile$.pipe(map((profile) => profile?.settings));
  }

  selectDarkMode(): Observable<boolean> {
    return this.selectSettings().pipe(map((settings) => !!settings?.darkMode));
  }

  selectDarkSidebar(): Observable<boolean> {
    return this.selectSettings().pipe(
      map((settings) => !!settings?.darkSidebar),
    );
  }

  selectDialogSetting(setting: ProfileSetting) {
    return this.selectSettings().pipe(
      map((settings) => settings && settings[setting]),
      first(),
    );
  }

  selectProfileWithAuth() {
    return this.user$.pipe(
      filter((as) => !!as),
      switchMap(() => this.selectLoading()),
      filter((l) => !l),
      first(),
      switchMap(() => this.profile$),
      filter((p) => p !== null),
    );
  }

  selectRoles() {
    return this.roles$;
  }

  selectReadOrSuperAdmin(): Observable<boolean> {
    return this.user$.pipe(
      switchMap((u) => (u ? u.getIdTokenResult() : of(null))),
      map(
        (tokenRes: any) =>
          tokenRes?.claims?.superAdmin || tokenRes?.claims?.readAdmin,
      ),
    );
  }

  selectDevSupport(): Observable<boolean> {
    return this.user$.pipe(
      switchMap((u) => (u ? u.getIdTokenResult() : of(null))),
      map((tokenRes: any) => tokenRes?.claims?.devSupport),
    );
  }

  selectSupportAdmin(): Observable<boolean> {
    return this.user$.pipe(
      switchMap((u) => (u ? u.getIdTokenResult() : of(null))),
      map((tokenRes: any) => tokenRes?.claims?.supportAdmin),
    );
  }

  selectClaims(): Observable<{
    superAdmin?: boolean;
    readAdmin?: boolean;
  } | null> {
    return this.user$.pipe(
      switchMap((u) => (u ? u.getIdTokenResult() : of(null))),
      map((tokenRes: any) => tokenRes?.claims),
    );
  }

  selectUID() {
    return this.uid$;
  }

  selectLanguage() {
    return this.profile$.pipe(
      map((profile) => profile?.language),
      distinctUntilChanged(),
    );
  }
}
